import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import 'firebase/auth';
import 'firebase/performance';
import { CookieService } from 'ngx-cookie-service';
import { of, throwError } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class AppInitializerService {
  constructor(private http: HttpClient, private cookieService: CookieService) {}

  async checkSessionCookie() {
    return new Promise<void>((resolve, reject) => {
      return this.http
        .get(`${environment.apiUrl}accounts/session-status/?isAuth=true`, { withCredentials: true })
        .subscribe(
          () => {
            resolve();
          },
          () => {
            const url = new URL(location.href);
            const redirectUri = url.searchParams.get('redirectUri');
            const state = url.searchParams.get('state');
            window.location.replace(`${redirectUri}/${state || ''}`);
            reject();
          }
        );
    });
  }
}
