import { Injectable } from '@angular/core';
import 'firebase/auth';
import { ConfirmationRemote } from './confirmation.remote';

@Injectable({ providedIn: 'root' })
export class ConfirmationService {
  constructor(private confirmationRemote: ConfirmationRemote) {}

  sendEmailConfirmation(token: string) {
    return this.confirmationRemote.sendEmailConfirmation(token);
  }

  updateVerification(token: string) {
    return this.confirmationRemote.updateVerification(token);
  }
}
