import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { trace } from '@angular/fire/performance';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { FirebaseError } from 'firebase';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { ChangePasswordDto } from 'src/app/models/change-password';

@Injectable()
export class ChangePasswordResolver implements Resolve<ChangePasswordDto> {
  constructor(private auth: AngularFireAuth) {}

  resolve(route: ActivatedRouteSnapshot) {
    const { code } = route.queryParams;
    return from(this.auth.verifyPasswordResetCode(code)).pipe(
      switchMap((email) => of({ email })),
      trace('auth_reset_password_validation'),
      catchError((error: FirebaseError) => of(error))
    );
  }
}
