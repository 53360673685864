import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { from, of } from 'rxjs';
import { catchError, switchMap, mergeMap } from 'rxjs/operators';
import { PlaybookError } from 'src/app/models/error';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { trace } from '@angular/fire/performance';
import { ConfirmationService } from '../../confirmation/services/confirmation.service';

@Injectable()
export class ConfirmationResolver implements Resolve<PlaybookError> {
  constructor(private auth: AngularFireAuth, private analytics: AngularFireAnalytics, private confirmationService: ConfirmationService) {}

  resolve(route: ActivatedRouteSnapshot) {
    const { code, token } = route.queryParams;
    return from(this.auth.applyActionCode(code)).pipe(
      trace('auth_email_confirmation'),
      mergeMap(() => this.confirmationService.updateVerification(token)),
      switchMap(() => of(null)),
      catchError((error) => {
        this.analytics.logEvent('auth_email_confirmation_error', error);
        return of({
          code: 'PlaybookErrorG11',
          message: error.message,
          token,
        });
      })
    );
  }
}
