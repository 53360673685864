import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class ConfirmationRemote {
  constructor(private http: HttpClient) {}

  sendEmailConfirmation(token: string) {
    return this.http.get(`${environment.apiUrl}accounts/send-verification?token=${token}`);
  }

  updateVerification(token: string) {
    return this.http.get(`${environment.apiUrl}accounts/verify?token=${token}`);
  }
}
