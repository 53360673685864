import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ApplicationResolver } from './modules/shared/services/application.resolver';
import { ConfirmationResolver } from './modules/shared/services/confirmation.resolver';
import { ChangePasswordResolver } from './modules/shared/services/reset-password.resolver';

const routes: Routes = [
  {
    path: 'sign_up',
    loadChildren: () => import('./modules/register/register.module').then((m) => m.RegisterModule),
    resolve: { result: ApplicationResolver },
  },
  {
    path: 'sign_in',
    loadChildren: () => import('./modules/sign-in/sign-in.module').then((m) => m.SignInModule),
    resolve: { result: ApplicationResolver },
  },
  {
    path: 'confirmation',
    loadChildren: () => import('./modules/confirmation/confirmation.module').then((m) => m.ConfirmationModule),
    resolve: { result: ConfirmationResolver },
  },
  {
    path: 'reset',
    loadChildren: () => import('./modules/reset/reset.module').then((m) => m.ResetModule),
    resolve: { result: ApplicationResolver },
  },
  {
    path: 'change-password',
    loadChildren: () => import('./modules/change-password/change-password.module').then((m) => m.ChangePasswordModule),
    resolve: { result: ChangePasswordResolver },
  },
  {
    path: 'setup',
    loadChildren: () => import('./modules/setup/setup.module').then((m) => m.SetupModule),
  },
  {
    path: 'redeem_invite',
    loadChildren: () => import('./modules/invite/invite.module').then((m) => m.InviteModule),
    resolve: { result: ApplicationResolver },
  },
  {
    path: '',
    loadChildren: () => import('./modules/login/check.module').then((m) => m.CheckModule),
    resolve: { result: ApplicationResolver },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
