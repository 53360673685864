// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://sandbox.api.playbook.vc/v1/',
  appUrl: 'https://sandbox.playbook.vc',
  cookieName: '_pb_sandbox',
  firebase: {
    apiKey: 'AIzaSyCHi6M8ryhzVxBOmNtyvE7CjbZ_UkPWy-8',
    authDomain: 'playbook-admin-sandbox.firebaseapp.com',
    databaseURL: 'https://playbook-admin-sandbox.firebaseio.com',
    projectId: 'playbook-admin-sandbox',
    storageBucket: 'playbook-admin-sandbox.appspot.com',
    messagingSenderId: '254083993719',
    appId: '1:254083993719:web:7ab7e288da86803278b672',
    measurementId: 'G-YY56JYSJZY',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
