import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { from } from 'rxjs';
import { Application } from 'src/app/models/application';
import { CheckEmailResponse, AuthenticationResponse } from 'src/app/models/response';
import { Credential } from 'src/app/models/session';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class CheckRemote {
  constructor(private http: HttpClient, private afAuth: AngularFireAuth) {}

  checkEmail(token: string) {
    const params = new HttpParams().set('token', token);
    return this.http.get<CheckEmailResponse>(`${environment.apiUrl}accounts/check`, { params });
  }

  checkApplication(application: Application) {
    let params = new HttpParams().set('clientId', application.clientId).set('redirectUri', application.redirectUri);
    if (application.invitation) {
      params = params.append('invitation', application.invitation);
    }
    return this.http.get(`${environment.apiUrl}apps/check`, { params });
  }
}
