import { Injectable } from '@angular/core';
import 'firebase/auth';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Application } from 'src/app/models/application';
import { CheckRemote } from './check.remote';

@Injectable({ providedIn: 'root' })
export class CheckService {
  constructor(private checkRemote: CheckRemote) {}

  checkEmail(email: string) {
    const token = btoa(email);
    return this.checkRemote.checkEmail(token).pipe(
      catchError((data) =>
        throwError({
          code: data.error.code,
          message: data.error.message,
        })
      )
    );
  }

  checkApplication(application: Application) {
    return this.checkRemote.checkApplication(application);
  }
}
