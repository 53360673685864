import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { HttpClient } from '@angular/common/http';
import { Application } from 'src/app/models/application';
import { CheckService } from '../../login/services/check.service';
import { tap, switchMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { PlaybookError } from 'src/app/models/error';

@Injectable()
export class ApplicationResolver implements Resolve<Application | PlaybookError> {
  constructor(private loginService: CheckService) {}

  resolve(route: ActivatedRouteSnapshot) {
    const { clientId, redirectUri, state, token, invitation, auth_on_api } = route.queryParams;
    return this.loginService.checkApplication({ clientId, redirectUri, state, invitation }).pipe(
      switchMap((payload) =>
        of({
          clientId,
          redirectUri,
          state,
          token,
          invitation: payload['invitation'],
          invitation_token: invitation,
          auth_on_api: auth_on_api === 'true',
        })
      ),
      catchError((res) =>
        of({
          code: res.error.code,
          message: res.error.message,
        })
      )
    );
  }
}
